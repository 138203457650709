:root {
	--primary-color-1: #00d1b2;
	--primary-color-2: #00a78e;
	--primary-color-3: #00826e;

	--field-color-1: #ffbe68;
	--field-color-2: #947346;

	--grey0: #ffffff;
	--grey1: #f5f5f5;
	--grey2: #eeeeee;
	--grey3: #e0e0e0;
	--grey4: #bdbdbd;
	--grey5: #9e9e9e;
	--grey6: #757575;
	--grey7: #616161;
	--grey8: #424242;
	--grey9: #212121;
	--grey10: #0f1111;
}

/* create dark and light classes */

.light-theme {
	--nav-main: var(--primary-color-1);
	--nav-accent: var(--primary-color-2);
	--nav-button-accent: var(--primary-color-3);
	--body-main: var(--grey1);
	--body-accent: var(--grey3);
	--body-accent-1: var(--grey1);
	--text-main: var(--grey9);
	--text-accent: var(--grey8);

	--field-color: var(--field-color-1);
}

.dark-theme {
	--nav-main: var(--primary-color-2);
	--nav-accent: var(--primary-color-1);
	--nav-button-accent: var(--primary-color-3);
	--body-main: var(--grey9);
	--body-accent: var(--grey7);
	--body-accent-1: var(--grey8);
	--text-main: var(--grey2);
	--text-accent: var(--grey8);
	--field-color: var(--field-color-2);
}

* {
	box-sizing: border-box;
}
body,
html {
	margin: 0;
	padding: 0;
	font-size: 16px;
	font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI',
		'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	color: var(--text-main);
	background: var(--body-main);
	transition: background 0.2s ease-in;
}

a {
	color: inherit;
}

a:hover {
	color: var(-grey9);
}

a.list-item {
	text-decoration: none;
	color: var(-grey9);
	background: var(--body-accent-1);
}

.list a.list-item:hover {
	background: var(--body-accent);
	color: var(--text-main);
	font-weight: bold;
}

h1,
h2 {
	margin: 1em 0;
	font-weight: 600;
	font-size: 2em;
	color: var(--text-main);
}

.hide-until-parent-hovered {
	display: none;
}

a:hover .hide-until-parent-hovered {
	display: inline;
}

.navigation-bar {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	min-height: 3.25rem;
	margin-bottom: 3em;
	color: var(--text-accent);
	background: var(--nav-main);
}
.navigation-bar-contatiner {
	display: flex;
	align-items: center;
	min-width: 60%;
}
.navigation-bar-brand {
	font-weight: bold;
	text-align: center;
	padding: 0.5rem 0.75rem;
}
.navigation-bar-button {
	padding: 0.6rem 0.85rem;
	border-radius: 2px;
	color: inherit;

	font-weight: 600;
	letter-spacing: 0.5px;
	background: var(--nav-accent);
}

.navigation-bar-button:hover {
	cursor: pointer;
	font-weight: 600;
	/* color: var(--body-main); */
	background: var(--nav-button-accent);
}
.navigation-bar-version {
	padding: 0.5rem 0.75rem;
	font-size: 0.75em;
	text-align: center;
}

.button-container {
	margin-bottom: 1.5em;
}

button[type='button'] {
	margin: 0.5em 0.75em;
	padding: 0.8rem 1.2rem;
	border-radius: 2px;
	border: none;
	font-weight: 600;
	letter-spacing: 0.8px;
}
button[type='button']:focus {
	outline: 0.3px dotted var(--body-accent);
}

.toolbar {
	display: flex;
	justify-content: left;
	align-items: center;
}

.toolbar > * {
	margin-right: 10px;
}

.record_group {
	border-bottom: solid 1px #eee;
}

.record_group:last-child {
	margin-bottom: 300px;
}

.record_group .fields {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: flex-start;
}

.label {
	color: var(--text-main);
}

.field_group {
	flex: 0 1 31%;
	display: inline-block;
	margin-bottom: 20px;
	margin-right: 10px;
	background: var(--body-accent);
	padding: 2px;
	border-radius: 3px;
}

.field_group.focused {
	background: #00d1b2;
}
.field_group.focused .label {
	/* color: white; */
	font-weight: bold;
}
.field_group.focused.valid .label {
	color: white;
}

.field_group.double-entry-error {
	background: #ff0000;
}

.field_group.double-entry-error .label {
	color: white;
}

.field_group .label {
	font-size: 0.8em;
	font-weight: normal;
	padding-left: 5px;
	padding-right: 5px;
}

.field_group.unknown:not(.focused) {
	opacity: 0.5;
}

.field_group.incomplete {
	background: var(--field-color);
}

.field_group.invalid {
	background: #ff9999;
	color: #ff0000;
}

.field_group.focused.invalid {
	background: #ff0000;
}

.field_group.focused.invalid .label {
	color: #ffffff;
}

.field_group.focused.invalid input {
	border: none;
}

.field_group.incomplete input:focus,
.field_group.incomplete select:focus {
	border-color: var(--field-color);
	outline-color: var(--field-color);
}

.record_field .field {
	margin: 0 !important;
}

.record_field .field .control,
.record_field .field .select {
	width: 100%;
}

.record_field .field .select select {
	width: 100%;
}

/* .record_field input, .record_field select {
  width: 100%;
  font-size: 1.0em;
  border: solid 1px #ddd;
  font-weight: bold;
  padding: 5px;
  border-radius: 4px;
} */

input.input,
.select select {
	outline-style: none;
	box-shadow: none;
	border-color: transparent;
}
input.input:focus,
.select select:focus {
	border-color: #00d1b2;
}

.field input::placeholder {
	padding: 3px;
}

.field .radios {
	background: white;
	padding: 5px;
	display: flex;
	flex-direction: column;
}

.field .radios label.radio {
	flex: 0 1 auto;
	margin: 0px;
	font-size: 0.8em;
	padding: 3px;
}

.field .radios label.radio:focus-within {
	background: #00d1b248;
	border-radius: 3px;
}

.field .radios label.radio.selected {
	font-weight: bold;
}

.field .radios label input {
	margin-right: 5px;
}

.descriptions {
	display: none;
	padding: 5px;
	font-size: 0.7em;
	font-style: italic;
}

.descriptions.show {
	display: inline-block;
}

.field_help {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 999;
	padding: 20px;
	background: #00d1b2;
	color: white;
	font-weight: bold;
	border-top: solid 1px;
	opacity: 0;

	transition: opacity 0.125s;
}

.field_help.visible {
	opacity: 1;
}

.field_help.valid-record {
	bottom: 75px !important;
}

.finalize-entry {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 999;
	background: #00a78e;
	padding: 20px;
}

.errors .error,
.warnings .warning {
	display: block;
	padding: 5px;
	border-radius: 5px;
	font-weight: normal;
	margin-bottom: 1px;
}

.errors .error {
	background: #ff0000;
	color: #ffffff;
}

.warnings .warning {
	background: var(--field-color);
	color: #ffffff;
}

.fileUploader {
	display: inline-block;
}

.list .hits {
	margin-left: 10px;
	color: #aaa;
}

.list .hits::before {
	/* font-style: italic; */
	/* content: 'Matches: '; */
}

.list .hits span {
	display: inline-block;
	margin-right: 0.5em;
	background: #aaa;
	color: white;
	padding-left: 10px;
	padding-right: 10px;
	margin-bottom: 2px;
	border-radius: 1em;
}

.search {
	position: relative;
	display: flex;
	margin-bottom: 5px;
}

.search input {
	margin-right: 5px;
	padding-left: 2em;
}

.search > .fa.fa-search {
	position: absolute;
	left: 10px;
	top: 8px;
	z-index: 99;
}

.search > .search-field {
	max-width: 200px;
	margin-right: 6px;
}

.list .list-item {
	display: flex;
}

.list .list-item.locked > * {
	opacity: 0.4;
}
.list .list-item.locked:hover > * {
	opacity: 1;
}

.list .list-item > * {
	flex: 1;
}

.list .list-item .pid {
	max-width: 30%;
}

.list .list-item .hits {
	max-width: 50%;
}

.list .list-item .sort-field {
	max-width: 20%;
	font-style: italic;
	text-align: right;
	margin-right: 10px;
}

.list .list-item:hover .button {
	opacity: 1;
}

.list .list-item .button {
	opacity: 0;
	max-width: 3em;
}

.sort {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}

.sort > * {
	margin-right: 10px;
}

.sort .select {
	max-width: 200px;
	margin-right: 10px;
}

.paging {
	display: flex;
	margin-right: 10px;
	align-items: center;
}

.paging > * {
	flex: 1;
	width: 2em;
}

.paging button {
	width: 10px !important;
	padding-left: 4px;
	padding-right: 4px;
}

.paging input {
	text-align: right;
	margin-right: 5px;
	padding-top: 4px;
	border: none;
	width: 3em;
	font-size: 16px !important;
	font-family: Roboto, sans-serif;
}

.paging input:focus {
	width: 3em;
}

.paging span {
	font-size: 16px !important;
	font-family: Roboto, sans-serif;
	margin-right: 8px;
}

span.issues {
	color: #ff0000;
	font-size: 0.75em;
}

span.issues .fa {
	margin-right: 3px;
}

.editor {
	display: flex;
	width: 100%;
	position: relative;
}

.editor .content {
	flex-shrink: 1;
}

.editor .validation {
	min-width: 300px;
	font-size: 0.7em;
	position: relative;
}

.editor .validation .validation-container {
	position: fixed;
	width: 300px;
	right: 20px;
	top: 250px;
	bottom: 0px;
}
.editor .validation .group {
	margin-bottom: 1em;
}

.editor .validation .group .group-name {
	font-weight: bold;
	font-size: 1.1em;
	margin-top: 2em;
}

.editor .validation .group .field {
	margin-top: 10px;
	margin-bottom: 2px;
	background: var(--body-accent);
	padding: 5px;
	border-radius: 3px;
	box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1);
}

.editor .validation .group .field .field-name {
	font-weight: bold;
	margin-bottom: 5px;
	padding: 5px;
}
.editor .validation .group .field .issue {
	display: flex;
	align-items: center;
	background: var(--body-main);
	padding: 10px;
	cursor: pointer;
}

.editor .validation .group .field .issue:hover {
	background: #f8f8f8;
}

.editor .validation .group .field .issue .fa {
	width: 20px;
	font-size: 2em;
}

.editor .validation .group .field .issue.error .fa {
	color: #ff0000;
}

.editor .validation .group .field .issue.warning .fa {
	color: var(--field-color);
}

.editor .validation .group .field .issue .description {
	border-left: solid 1px #aaa;
	margin-left: 10px;
	padding-left: 10px;
}

@media print {
	body {
		font-size: 0.6em;
	}

	input,
	select,
	.select,
	.input {
		border: none !important;
	}

	input.input,
	.select > select {
		padding-top: 0;
		padding-bottom: 0;
		margin-top: 0 !important;
		margin-bottom: 0 !important;
		height: auto;
	}

	.select select {
		font-size: 0.8em !important;
	}

	input.input,
	.control.radios label {
		font-size: 64% !important;
	}

	.control .select select::after {
		display: none !important;
	}

	.button,
	button,
	h1.title,
	.toolbar {
		display: none;
		background: red;
	}

	.record_group {
		border-bottom: none;
		page-break-inside: avoid;
	}

	.record_group .title {
		font-size: 1em;
		margin: 2em 0 1em 0;
	}

	.record_group .fields {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: auto;
	}

	.fields .field_group {
		flex: 0 1 33%;
		width: auto;
		border: solid 1px black;
		color: black;
		line-height: 1.2;
		padding-top: 2px;
		padding-bottom: 2px;
		min-height: 42px;
	}

	.field_group,
	.field_group.incomplete,
	.field_group.field_group.focused,
	.field_group.invalid,
	.field_group.unknown,
	.field_group.unknown:not(.focused) {
		background: white;
		margin: 0px;
		margin-right: -1px;
		margin-bottom: -1px;
		border-radius: 0;
		opacity: 1;
	}

	.field_group .label {
		font-weight: bold;
		margin-bottom: 0;
	}
}

.is-disabled {
	color: gray;
	border-color: gray;
}
